// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("channels")
require('jquery')
import 'bootstrap'
import 'bootstrap-daterangepicker'

window.noUiSlider = noUiSlider

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import {
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons'

library.add(faCaretRight)

$(document).ready(function () {

  var btn = $('.waiting-btn');
  btn.on('click', function () {
    $(this).text('Carregando...');
    $(this).addClass('spinner spinner-left spinner-darker-success pl-15 disabled');
  });

  $('.format-date-time').each(function (i, date) {
    if (date.innerHTML != "") {
      const localDate = new Date(date.textContent.trim());
      const formattedDate = moment(localDate).format('DD/MM/YYYY HH:mm');
      $(date).text(formattedDate);
    } else {
      $(date).text('-');
    }
  })

  $('.format-date').each(function (i, date) {
    if (date.innerHTML != "") {
      const localDate = new Date(date.textContent.trim());
      const formattedDate = moment(localDate).format('DD/MM/YYYY');
      $(date).text(formattedDate);
    } else {
      $(date).text('-');
    }
  })

  $('#created_at_date_picker').daterangepicker({
    buttonClasses: ' btn',
    applyClass: 'btn-primary',
    cancelClass: 'btn-secondary',
    locale: {
      applyLabel: 'OK',
      cancelLabel: 'Cancelar'
    }
   }, function(start, end, label) {
    $('#created_at_date_picker .form-control').val( start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
   });

   $('#last_sign_in_date_picker').daterangepicker({
    buttonClasses: ' btn',
    applyClass: 'btn-primary',
    cancelClass: 'btn-secondary',
    locale: {
      applyLabel: 'OK',
      cancelLabel: 'Cancelar'
    }
   }, function(start, end, label) {
    $('#last_sign_in_date_picker .form-control').val( start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
   });

   $('#subscription_created_at_date_picker').daterangepicker({
    buttonClasses: ' btn',
    applyClass: 'btn-primary',
    cancelClass: 'btn-secondary',
    locale: {
      applyLabel: 'OK',
      cancelLabel: 'Cancelar'
    }
   }, function(start, end, label) {
    $('#subscription_created_at_date_picker .form-control').val( start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
   });

   $('#subscription_suspended_at_date_picker').daterangepicker({
    buttonClasses: ' btn',
    applyClass: 'btn-primary',
    cancelClass: 'btn-secondary',
    locale: {
      applyLabel: 'OK',
      cancelLabel: 'Cancelar'
    }
   }, function(start, end, label) {
    $('#subscription_suspended_at_date_picker .form-control').val( start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
   });

   $('#subscription_cancelled_at_date_picker').daterangepicker({
    buttonClasses: ' btn',
    applyClass: 'btn-primary',
    cancelClass: 'btn-secondary',
    locale: {
      applyLabel: 'OK',
      cancelLabel: 'Cancelar'
    }
   }, function(start, end, label) {
    $('#subscription_cancelled_at_date_picker .form-control').val( start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
   });

   $('#last_ad_mined_at_date_picker').daterangepicker({
    buttonClasses: ' btn',
    applyClass: 'btn-primary',
    cancelClass: 'btn-secondary',
    locale: {
      applyLabel: 'OK',
      cancelLabel: 'Cancelar'
    }
   }, function(start, end, label) {
    $('#last_ad_mined_at_date_picker .form-control').val( start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
   });

   $('#by_events_created_at_date_picker').daterangepicker({
    buttonClasses: ' btn',
    applyClass: 'btn-primary',
    cancelClass: 'btn-secondary',
    locale: {
      applyLabel: 'OK',
      cancelLabel: 'Cancelar'
    }
   }, function(start, end, label) {
    $('#by_events_created_at_date_picker .form-control').val( start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
   });
});

dom.watch();
